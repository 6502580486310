import Vue from 'vue';
window._ = require('lodash');
window.axios = require('axios');
/* --------------------------------------------------------------------------------- */

import man_catalogue from './pages/personal_study_profile/embed/catalogue.vue';
Vue.component('catalogue', man_catalogue);

// <script type="text/javascript" src='/res/front_en/js/sert.js' async></script>  // подключаем вью на сайт
// // <div id="sert"> // айди по которому вью инициализируется
// // <sert_block sec_key="zYgGAsVkXe" company="proplex" url_site="http://project/" :size_width="325"
// //     :size_height="210"> </sert_block> //  блок по которому вью выводит данные
// //     </div>


/**
 *    объявление блока для вывода Vue.js
 *    пример:
 *    <div id="app">
 *    <model3d></model3d>
 *    </div>
 * @type {Vue}
 */

const manufacturer_catalogue = new Vue({
    el: '#catalogue',
    mounted() {
        this.loading = false;
    },
    data() {
        return {
            loading: true,
        }
    },
    methods: {}
});

